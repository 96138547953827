.form-validate label{
    font-size: 19px;
    padding-bottom: .5rem;
}

.login-overlay .panel-body{
    min-height: 244px;
}
.has-feedback{
    position: relative;
}
.form-control-feedback{
    right: 10px;
    left: auto;
    top: 10px;
    position: absolute;
}

.buttons-box{
    display: flex;
    align-items: center;
    margin:0px;
}

.wrapper{
    display: flex;
    position: absolute;
    top: -97px;
    right: 10%;
    width: 34em;
    height: 100vh;
    z-index: -5;
}

.wrapper-login{
    width: 34em;
    position: absolute;
    right: 10%;
    height: 100vh;
}

.form{
    padding: 2em 5em;
}

.form p{
    text-align: center;
    margin-bottom: 0px;
}

.form a{
    text-decoration: none;
    color: #fff;
    font-size: 12px;
}

.form a:hover{
    color:#fff;
}

.form label{
    font-family: "Roboto-Bold";
    color: #fff;
    margin-bottom: 0px;
    font-size: 19px;
    padding-bottom: .5rem;
}

.form input{
    width: 100%;
    height: 3em;
    border-radius: 5px;
}

.form input[placeholder]{
    font-family: "Roboto-Light";
    font-size: 14px;
}

.form button{
    font-family: "Roboto-Medium";
    font-size: 16px;
    border-radius: 2px;
    width: 100%;
    height: 65px;
    border: 0px;
    margin: 0px;
}

.form-contact{
    background: #f5f5f6;
}
.inputLabeled{
    color: #C7C9C7;
    position: relative;
}
.inputLabeled label{
    position: absolute;
    z-index: 2;
    font-size: 0.7rem;
    left: 1rem;
    top: 1rem;
}
.inputLabeled .form-control{
    height: 3.6rem !important;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btnSend {
    width: 100%;
}
.text-primary{
    color: #D50032 !important;
}

.card-title{
    margin: 0em 1.8em;
    line-height: 35px;
    font-family: "Montserrat-Regular";
    font-size: 35px;
    text-align: left;
    color: #fff;
}

.navbar{
    padding-right: 0;
    z-index: 999;
}

.Navbar-Logo{
    margin-left: 5%;
}
.Navbar-img{
    height: 100%;
    width: 10em;
}
.Navbar-img img{
    position: relative;
    width: 100%;
    height: 100%;
    margin-right: 1em;
}
.Navbar-Logo span{
    height: 100%;
}
.Navbar-line{
    border: 1px solid #fff;
    padding: 15px 0px;
    margin: 0em 0.7em;
    width: 2px;
}
.Navbar-text{
    font-family: "Roboto-Black-Italik";
    font-size: 2em;
    color: #fff;
    position: relative;
    vertical-align: middle;
}
.navbar-nav a:hover{
    text-decoration: none;
}

.card-login {
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
    padding: 12em 0em 0em 0em;
    width: 100%;
    min-height: 100vh;
    height: 100%;
}

.modal-open .modal {
    background: rgba(0, 0, 0, 0.5);
}

.modal-title{
    font-size: 1.8rem;
    font-family: "Montserrat-SemiBold";
}

.modal-body{
    padding: 2.875rem 2.1875rem
}

.modal-body input{
    height: 3em;
    border: 1px solid #C7C9C7;
}
.modal-body input[placeholder]{
    font-family: "Roboto-Light";
    /* color: #C7C9C7; */
}

.modal-body p{
    font-family: "Montserrat-Medium";
    font-size: 1em;
    margin: auto 0;
    color: #252F38;
}
.modal-body .btn-light{
    background-color: #E5E5E5;
}

.modal-body button{
    height: 4em;
    font-size: 1em;
    font-family: "Roboto-Medium";
}

#register{
    position: absolute;
    width: 100%;
}

#register .card-register{
    padding-top: 117px;
}

#register h5{
    padding: 0.6em 1.8em;
    font-family: "Montserrat-Regular";
    font-size: 40px;
    text-align: left;
    color: #fff;
}

#register .description{
    margin: 0em 5.1em;
    font-family: "Montserrat-SemiBold";
    font-size: 14px;
    text-align: left;
    color: #fff;
}


#register label{
    font-family: "Roboto-Bold";
    color: #fff;
    margin-bottom: 0px;
    font-size: 19px;
    padding-bottom: .5rem;
}

#register p{
    margin-bottom: 1em;
}

#register .form-group input[placeholder]{
    font-family: "Roboto-Light";
    font-size: 16px;
}

#register .have-account{
    font-family: "Montserrat-Regular";
    font-size: 12px;
    padding-top: 16px;
    color: #fff;
    text-align: center;
}

#register .have-account a{
    text-decoration: none;
    color: #fff;
    text-decoration: underline;
}

#register .have-account a:hover{
    color: #fff;
    text-decoration: underline;
}

#register button{
    font-family: "Roboto-Medium";
    font-size: 16px;
    border-radius: 2px;
    width: 100%;
    height: 65px;
    border: 0px;
    margin: 0px;
}

#register .checkbox{
    position: relative;
    margin-left: 23px;
}

#register .checkbox label{
    color:#fff;
}

#register .checkbox label{
    font-family: "Montserrat-Bold";
    font-size: 9px;
}

#register .c-checkbox span{
    border: 2px solid #D50032;
    width: 15px;
    height: 14px;
    background: #fff 0% 0% no-repeat padding-box;
    position: absolute;
    left: -23px;
}

#register .termsCheck .invalid-feedback{
    display: block;
    top: 70px;
    left: 22px;
    position: absolute;
}

.c-checkbox span:hover{
    border: 2px solid #D50032;
}

#register .c-checkbox span:before{
    font-size: 9px;
    line-height: 0px;
    top: 50%;
    width: 9px;
    height: 7px;
}

#register .c-checkbox input[type=checkbox]:checked + span{
    border-color: #D50032;
    background-color: #fff;
}

#register .c-checkbox input[type=checkbox]:checked + span:before{
    color:#D50032;
}


.checkbox{
    position: relative;
    margin-left: 23px;
}
.checkbox label{
    color: #fff;
    font-family: "Montserrat-Bold";
    font-size: 9px;
}

.c-checkbox span{
    border: 2px solid #D50032;
    width: 15px;
    height: 14px;
    background: #fff 0% 0% no-repeat padding-box;
    position: absolute;
    left: -23px;
}
.c-checkbox:hover span{
    border-color: #D50032;
}
.c-checkbox span:before{
    font-size: 9px;
    line-height: 0px;
    top: 50%;
    width: 9px;
    height: 7px;
}
.c-checkbox input[type=checkbox]:hover{
    border: #D50032;
}
.c-checkbox input[type=checkbox]:checked + span{
    border-color: #D50032;
    background-color: #fff;
}
.c-checkbox input[type=checkbox]:checked + span:before{
    color:#D50032;
}


 .Link{
    font-family: "Roboto-Medium";
    color: #fff;
    font-size: 1.2rem;
    text-align: left;
}

.Link span a{
    font-family: "Roboto-Medium";
    font-size: 1em;
    color: #fff;
}

.Link span a:hover{
    color:#fff;
    text-decoration:none;
}

.navbar-nav a{
    font-family: "Roboto-Regular";
    font-size: 1.2em;
    color: #A7A7A7;
}

.navbar-nav .nav-link {
    color:#fff;
    font-family: "Roboto-Medium";
    font-size: 1em;
}
.navbar-nav a:hover{
    color: #a7a7a7;
}
.navbar-nav button:hover{
    background-color: #D50032;
    border-color: #D50032;
    box-shadow: none;
}
.navbar-nav .btn-danger.dropdown-toggle{
    background-color: #D50032;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}
.navbar-nav .dropdown-menu.show{
    transform: translate3d(-58%, 15%, 0px);
}

.dropdown-item {
    color: #A7A7A7;
}
.dropdown-item:focus{
    color:#A7A7A7;
}
.dropdown-item:hover{
    color: #A7A7A7;
}

#HomePage .checkbox label{
    font-family: "Montserrat-Regular";
    font-size: 1em;
    vertical-align: -webkit-baseline-middle;
}

#HomePage .c-checkbox span{
    width: 20px;
    height: 19px;
    top: 5px;
}
#HomePage .c-checkbox span:before{
    width: 15px;
    height: 10px;
}

.card-newTrip{
    padding-top: 10em;
}
.card-confirmTrip{
    padding: 2em 0em 0em 0em;
}

.Line-Circle{
    border-radius: 50%;
    background-color: #fff;
    width: 10px;
    height: 10px;
}
.Line-Line{
    border: 1px solid #fff;
    background-color: #fff;
    height: 100%;
    width: 1px;
    margin: 0 4px !important;
}
.Line-Square{
    background-color: #fff;
    width: 10px;
    height: 10px;
}
#ConfirmTrip, #register-form,
#form-newTrip, #form-updateTrip{
    background: #252F38;
}
#ConfirmTrip .Summary{
    position: relative;
    left: 14px;
}
#ConfirmTrip label{
    font-family: "Montserrat-Bold";
    color: #fff;
}

#ConfirmTrip span{
    font-family: "Montserrat-Regular";
    font-size: 1.3em;
    color: #fff;
}

#ConfirmTrip p{
    margin-bottom: 0px;
    padding-bottom: 21px;
    text-align: left;
}

#ConfirmTrip .Amount{
    background-color: #fff;
}
#ConfirmTrip .Amount .Sub-Amount label{
    font-family: "Montserrat-Regular";
    padding: 5px 0px;
    color: #000;
    position: relative;
    top: 10%;
    margin-bottom: 0px;
}
#ConfirmTrip .Amount p{
    color: #000;
    text-align: right;
    padding: 0px 21px;
}

#ConfirmTrip .Total-Amount{
    background-color: #000;
    height: 60px;
}
#ConfirmTrip .Total-Amount label{
    top: 20%;
    position: relative;
}
#ConfirmTrip button{
    font-family: "Roboto-Medium";
    font-size: 16px;
    border-radius: 2px;
    width: 100%;
    height: 65px;
    border: 0px;
    margin: 0px;
}

.cardTrip{
    font-family: "Montserrat-Regular";
    font-size: 1.2em;
    color: #707070;
}
.cardTrip button{
    font-family: "Montserrat-Semibold";
    font-size: 0.8em;
    width: 125px;
}

.cardTrip .btn-light{
    font-family: "Montserrat-Medium";
    background-color: #fff;
    border: none;
    box-shadow: none;
    width: 100%;
}
.cardTrip .btn-danger,
.cardTrip .btn-success,
.cardTrip .btn-warning,
.cardTrip .btn-info{
    border-radius: 15px;
    padding: 0.5em 1.5em;
}


.SideBar{
    padding: 1em 2em;
    list-style: none;
}
.SideBar .nav-item{
    padding: 0.8em 0em;
    display: block;
    text-decoration: none;
    padding-left: 1em;
    color: #7F7F7F;
    font-family: "Montserrat-Regular";
}
.SideBar .nav-item:hover{
    background-color: #EBEBEB;
}
.SideBar .active{
    background-color: #EBEBEB;
}

.SideBar .nav-item a{
    color: #7F7F7F;
    text-decoration: none;
    width: 100%;
    font-family: "Montserrat-Regular";
    font-size: 1em;
    padding-left: 1em;
}

.btn-outline-light:hover{
    background-color: #E5E5E5;
}

.mapResponsive2{
    height: 200px;
}
@media (max-height: 750px){
    .wrapper,
    .wrapper-login{
        height: inherit !important;
    }
}
@media (min-height: 751px){
    .wrapper,
    .wrapper-login{
        height: 100vh !important;
    }
}

@media (max-width: 576px) {
    .wrapper,
    .wrapper-login{
        right: 0%;
        width: 100%;
        height: inherit; /* 635px; */
    }
    .form{
        padding: 1em 2em;
    }
    #register .description{
        margin: 1em 2em;
    }
    #register h5{
        padding: 0.4em 0.7em;
    }
    #Navbar{
        padding-left: 0.5em;
    }
    .card-title{
        margin: 0 0.8em;
    }
    .Line{
        top: 6em;
        position: absolute;
        height: 33%;
        left: -1.5em;
    }

    .navbar-toggler{
        margin-right: 2%;
    }
    .Navbar-img{
        width: 6em;
    }
    .Navbar-line{
        padding: 5px 0px;
    }
    .Navbar-text{
        font-size: 1.3em;
    }
    .collapse,
    .navbar-collapse{
        padding-right: 2%
    }
    .col-md-12{
        width: fit-content;
    }
}

@media (min-width: 577px) {
    .wrapper{
        right: 5%;
        height: inherit;
    }
    .wrapper-login{
        right: 5%;
        height: 100vh;
        overflow: hidden;
    }
    .navbar-toggler{
        margin-right: 5%;
    }
    .form{
        padding: 2em 5em;
    }
    .collapse,
    .navbar-collapse{
        padding-right: 5%
    }
}

@media only screen and (min-width: 768px){
    .btnSend {
        width: 40%;
        max-width: 18rem;
    }
    .btnSend b{
        font-size: 1rem;
        line-height: 1.5;
    }
}

@media (min-width: 992px) {
    .wrapper{
        right: 5%;
        height: inherit;
    }
    .wrapper-login{
        right: 5%;
        height: 100vh;
    }
    .collapse,
    .navbar-collapse{
        padding-right: 5%;
    }
}

@media (min-width: 1200px) {
    .collapse{
        padding-right: 10%;
    }
    .wrapper,
    .wrapper-login{
        height: 100vh;
        right: 10%;
    }
    .wrapper-register{
        height: inherit;
    }
}

@media (min-width: 1500px){
    .wrapper-register{
        height: 100vh;
    }
}

.db-font-size {
    font-size: 19px !important;
}
