
/* // Will edit everything selected including everything between a range of dates */
.CalendarDay__selected_span {
    background: #dc3545; /* //background */
    color: white; /* //text */
    border: 1px solid #dc3545; /* //default styles include a border */
  }
  
/*   // Will edit selected date or the endpoints of a range of dates */
  .CalendarDay__selected {
    background: darkred;
    color: white;
  }
  
/*   // Will edit when hovered over. _span style also has this property */
  .CalendarDay__selected:hover {
    background: #dc3545;
    color: white;
  }
  
/*   // Will edit when the second date (end date) in a range of dates
  // is not yet selected. Edits the dates between your mouse and said date */
  .CalendarDay__hovered_span:hover,
  .CalendarDay__hovered_span {
    background: brown;
  }
  
  .DayPickerKeyboardShortcuts_show__bottomRight{
    width: 10% !important;
  }

  .DayPickerKeyboardShortcuts_show__bottomRight::before{
    border-right: 33px solid #D50032;
  }

  .DateInput .DateInput_1, 
  .SingleDatePicker, 
  .SingleDatePickerInput__withBorder,
  .DateInput{
    width: 100%;
  } 

  .DateInput_input__focused{
    border-bottom: 2px solid #D50032;
  }

  .CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
    background: #D50032;;
    border: #dc3545;
  }
