
.autocomplete-container {
    position: relative;
    z-index: 4;
}

.autocomplete-dropdown-container {
    background: white;
    border: 1px solid honeydew;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.select-option {
    cursor: pointer;
}

.select-option:hover {
    background: #d50032;
    color: white;
}