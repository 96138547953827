@font-face {
  font-family: "Montserrat-Regular";
  src: url("../../style/fonts/montserrat/Montserrat-Regular.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url("../../style/fonts/montserrat/Montserrat-Bold.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("../../style/fonts/montserrat/Montserrat-SemiBold.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat-Medium";
  src: url("../../style/fonts/montserrat/Montserrat-Medium.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto-Bold";
  src: url("../../style/fonts/Roboto/Roboto-Bold.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto-Medium";
  src: url("../../style/fonts/Roboto/Roboto-Medium.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face{
  font-family: "Roboto-Light";
  src: url("../../style/fonts/Roboto/Roboto-Light.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto-Regular";
  src: url("../../style/fonts/Roboto/Roboto-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face{
  font-family: "Roboto-Black-Italik";
  src: url("../../style/fonts/Roboto/Roboto-BlackItalic.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face{
  font-family: "Helvetica-Neue-Regular";
  src: url("../../style/fonts/helvetica-neue/Helvetica45Light_22437.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face{
  font-family: "Helvetica-Neue-Medium";
  src: url("../../style/fonts/helvetica-neue/Helvetica65Medium_22443.ttf")
}