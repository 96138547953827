/*
|----------------------------------------------------------------------------
	Time Picker - Multipurpose Responsive Time Picker main CSS
	Author: MGScoder
	Author URL: https://codecanyon.net/user/mgscoder
	Version: 1.2
|----------------------------------------------------------------------------
*/

/*
|------------------------------------------------------------------
	[Table of contents]:

	1. FONTS
	2. TIMEPICKER CSS
	3. OPTIONAL CSS
	4. FORM STYLE
	5. TIME PICKER STYLE
	
|-------------------------------------------------------------------
*/

/*
|----------------------------------------------------------------------------
	1. FONTS: Google Font
|----------------------------------------------------------------------------
*/
@import url('https://fonts.googleapis.com/css?family=Poppins:400,400i,700,700i');


/*
|----------------------------------------------------------------------------
	2. TIMEPICKER CSS
|----------------------------------------------------------------------------
*/
.bootstrap-timepicker {
  position: relative;
}
.bootstrap-timepicker.pull-right .bootstrap-timepicker-widget.dropdown-menu {
  left: auto;
  right: 0;
}
.bootstrap-timepicker.pull-right .bootstrap-timepicker-widget.dropdown-menu:before {
  left: auto;
  right: 12px;
}
.bootstrap-timepicker.pull-right .bootstrap-timepicker-widget.dropdown-menu:after {
  left: auto;
  right: 13px;
}
.bootstrap-timepicker .input-group-addon {
  cursor: pointer;
}
.bootstrap-timepicker .input-group-addon i {
  display: inline-block;
  width: 16px;
  height: 16px;
}
.bootstrap-timepicker-widget.dropdown-menu {
  padding: 4px;
}
.bootstrap-timepicker-widget.dropdown-menu.open {
  display: inline-block;
}
.bootstrap-timepicker-widget.dropdown-menu:before {
  border-bottom: 7px solid rgba(0, 0, 0, 0.2);
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  content: "";
  display: inline-block;
  position: absolute;
}
.bootstrap-timepicker-widget.dropdown-menu:after {
  border-bottom: 6px solid #FFFFFF;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  content: "";
  display: inline-block;
  position: absolute;
}
.bootstrap-timepicker-widget.timepicker-orient-left:before {
  left: 6px;
}
.bootstrap-timepicker-widget.timepicker-orient-left:after {
  left: 7px;
}
.bootstrap-timepicker-widget.timepicker-orient-right:before {
  right: 6px;
}
.bootstrap-timepicker-widget.timepicker-orient-right:after {
  right: 7px;
}
.bootstrap-timepicker-widget.timepicker-orient-top:before {
  top: -7px;
}
.bootstrap-timepicker-widget.timepicker-orient-top:after {
  top: -6px;
}
.bootstrap-timepicker-widget.timepicker-orient-bottom:before {
  bottom: -7px;
  border-bottom: 0;
  border-top: 7px solid #999;
}
.bootstrap-timepicker-widget.timepicker-orient-bottom:after {
  bottom: -6px;
  border-bottom: 0;
  border-top: 6px solid #ffffff;
}
.bootstrap-timepicker-widget a.btn,
.bootstrap-timepicker-widget input {
  border-radius: 4px;
}
.bootstrap-timepicker-widget table {
  width: 100%;
  margin: 0;
}
.bootstrap-timepicker-widget table td {
  text-align: center;
  height: 30px;
  margin: 0;
  padding: 2px;
}
.bootstrap-timepicker-widget table td:not(.separator) {
  min-width: 30px;
}
.bootstrap-timepicker-widget table td span {
  width: 100%;
}
.bootstrap-timepicker-widget table td a {
  border: 1px transparent solid;
  width: 100%;
  display: inline-block;
  margin: 0;
  padding: 8px 0;
  outline: 0;
  color: #333;
}
.bootstrap-timepicker-widget table td a:hover {
  text-decoration: none;
  background-color: #eee;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border-color: #ddd;
}
.bootstrap-timepicker-widget table td a i {
  margin-top: 2px;
  font-size: 18px;
}
.bootstrap-timepicker-widget table td input {
  width: 25px;
  margin: 0;
  text-align: center;
}
.bootstrap-timepicker-widget .modal-content {
  padding: 4px;
}
@media (min-width: 767px) {
  .bootstrap-timepicker-widget.modal {
    width: 200px;
    margin-left: -100px;
  }
}
@media (max-width: 767px) {
  .bootstrap-timepicker {
    width: 100%;
  }
  .bootstrap-timepicker .dropdown-menu {
    width: 100%;
  }
}

/*
|----------------------------------------------------------------------------
	3. OPTIONAL CSS
|----------------------------------------------------------------------------
*/
#reservation-form-section {
    color: #222;
    font-family: 'Poppins', sans-serif;
	font-size: 16px;
	line-height: 1.48;
	padding: 100px 15px 100px;
}
#reservation-form-section .reservation-form-note {
    text-align: center;
    color: #ff0000;
    font-size: 20px;
    margin: 30px 0;
}
#reservation-form-section .reservation-form-title {
    font-size: 38px;
    color: #fb9902;
    text-align: center;
    border-bottom: 5px solid;
    font-weight: bold;
}
#reservation-form-section .text-center {
	text-align:center;
}
#reservationForm .style-point {
	color: #0147fe;
	font-size: 38px;
	margin-top: 80px;
}
#reservationForm .style-point span {
    background-color: #0147fe;
	color: #fff;
	padding: 10px;
	border: 1px solid #0147fe;
	border-radius: 50%;
}
#reservation-form-section.nonbootstrapForm #reservationForm {
	width: 520px;
	max-width: 100%;
	margin: 0 auto;
}
#reservationForm .form-group {
	display: table;
    position: relative;
	width: 100%;
    margin: 0 0 30px;
}


/*
|----------------------------------------------------------------------------
	4. FORM STYLE
|----------------------------------------------------------------------------
*/
.bootstrap-timepicker .form-control {
	display: block;
	width: calc(100% - 24px);
	height: 40px;
	padding: 6px 12px;
	font-size: 16px;
	line-height: 1.42857143;
	color: #555;
	background-color: #fff;
	background-image: none;
	/* border: 1px solid #D50032; */
	border-radius: 6px 0 0 6px;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
		  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
	-webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
	   -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
		  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}
.bootstrap-timepicker .form-control:focus {
	box-shadow: 0 1px 1px rgba(213, 0, 50, 0.075) inset, 0 0 8px rgba(251, 153, 2, 0.6);
}

/*
|----------------------------------------------------------------------------
	5. TIME PICKER STYLE
|----------------------------------------------------------------------------
*/
@font-face {
	font-family: 'Glyphicons Halflings';

	src: url('../fonts/glyphicons-halflings-regular.eot');
	src: url('../fonts/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/glyphicons-halflings-regular.woff2') format('woff2'), url('../fonts/glyphicons-halflings-regular.woff') format('woff'), url('../fonts/glyphicons-halflings-regular.ttf') format('truetype'), url('../fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular') format('svg');
}
.timepicker .glyphicon, .bootstrap-timepicker-widget .glyphicon {
	position: relative;
	top: 1px;
	display: inline-block;
	font-family: 'Glyphicons Halflings';
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.timepicker .glyphicon-time::before {
	content: "\e023";
}
.bootstrap-timepicker-widget .glyphicon-chevron-up::before {
	content: "\e113";
}
.bootstrap-timepicker-widget .glyphicon-chevron-down::before {
	content: "\e114";
}

.bootstrap-timepicker-widget, .bootstrap-timepicker-widget input {
    font-family: 'Poppins', sans-serif;
}
.bootstrap-timepicker .input-group-addon {
	padding: 6px;
	font-size: 14px;
	font-weight: 400;
	line-height: 1;
	color: #555;
	text-align: center;
	background-color: #D50032;
	border: 1px solid #D50032;
	border-radius: 0 6px 6px 0;
}
.bootstrap-timepicker .input-group-addon i {
    width: 24px;
    height: 24px;
}
.bootstrap-timepicker .input-group-addon, .bootstrap-timepicker .input-group-btn {
	width: 1%;
	white-space: nowrap;
	vertical-align: middle;
}
.input-group.bootstrap-timepicker  .form-control, .bootstrap-timepicker  .input-group-addon, .bootstrap-timepicker  .input-group-btn {
	display: table-cell;
}
.bootstrap-timepicker .input-group-addon {
	font-size: 24px;
	color: #fff;
	text-align: center;
}
.bootstrap-timepicker .input-group-addon, .bootstrap-timepicker .input-group-btn {
	white-space: nowrap;
}
.input-group.bootstrap-timepicker {
	position: relative;
	display: table;
	border-collapse: separate;
}

.bootstrap-timepicker-widget.dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1000;
	display: none;
	float: left;
	min-width: 160px;
	padding: 5px 0;
	margin: 2px 0 0;
	font-size: 14px;
	text-align: left;
	list-style: none;
	background-color: #fff;
	-webkit-background-clip: padding-box;
		  background-clip: padding-box;
	border: 1px solid #ccc;
	border: 1px solid rgba(0, 0, 0, .15);
	border-radius: 4px;
	-webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
		  box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
}
.bootstrap-timepicker-widget table td input {
    width: 60px;
    font-size: 32px;
    padding: 5px;
	border: 1px solid #fb9902;
	border-radius: 6px;
	-webkit-box-shadow: rgba(58,78,95,0.2) 0 10px 16px, rgba(58,78,95,0.05) 0 -5px 16px;
	box-shadow: rgba(58,78,95,0.2) 0 10px 16px, rgba(58,78,95,0.05) 0 -5px 16px;
}
.bootstrap-timepicker-widget table td span.glyphicon {
    font-size: 32px;
	color: #D50032;
}
.bootstrap-timepicker-widget table td span.bootstrap-timepicker-hour,
.bootstrap-timepicker-widget table td span.bootstrap-timepicker-minute,
.bootstrap-timepicker-widget table td span.bootstrap-timepicker-second,
.bootstrap-timepicker-widget table td span.bootstrap-timepicker-meridian {
    font-size: 32px;
    padding: 10px;
}